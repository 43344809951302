import React from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'

import Header from './components/Header'
import Footer from './components/Footer'

import Atuacao from './pages/Atuacao'
import Contato from './pages/Contato'
import Home from './pages/Home'
import Projetos from './pages/Projetos'
import QuemSomos from './pages/QuemSomos'
import Servicos from './pages/Servicos'
import TrabalheConosco from './pages/TrabalheConosco'

export default function Routes() {
  return (
    <>
      <Router hashType="noslash">
        <Header />
        <div className="main-content">
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/quemsomos" component={QuemSomos} />
            <Route path="/atuacao" component={Atuacao} />
            <Route path="/projetos" component={Projetos} />
            <Route path="/servicos" component={Servicos} />
            <Route path="/contato" component={Contato} />
            <Route path="/trabalhe" component={TrabalheConosco} />
          </Switch>
        </div>
        <Footer />
      </Router>
    </>
  )
}
