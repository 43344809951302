import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

import logoIod from '../../assets/logo.png'
import {TopHeader} from './styles'

export default function Header() {

  const [scroll, setScroll] = useState(0)
  
  function handleScroll() {
    setScroll(window.scrollY)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
  }, [scroll])

  return (
    <TopHeader className={(scroll !== 0)? 'roledUp': ''}>
      <Link to="/">
        <h1>
          <img src={logoIod} alt="IOD Engenharia" /> IOD ENGENHARIA
        </h1>
      </Link>
      <ul>
        <li><Link to="/quemsomos">Quem somos</Link></li>
        <li><Link to="/atuacao">Áreas de atuação</Link></li>
        <li><Link to="/servicos">Serviços</Link></li>
        <li><Link to="/projetos">Projetos</Link></li>
        <li><Link to="/contato">Fale conosco</Link></li>
      </ul>
    </TopHeader>
  )
}