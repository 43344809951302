import React from 'react'

import Carousel from '../components/Carousel'
import imagem001 from '../assets/atuacao-001.jpg'
import imagem002 from '../assets/atuacao-002.jpg'

import {Content, Title, Text, Font} from './styles'

export default function Atuacao() {
  return (
    <>
      <Carousel images={[imagem001, imagem002]} />
      <Content>
        <Title className="header">ÁREA DE ATUAÇÃO</Title>
        <Title>Acompanhamento Técnico</Title>
        <Text>
        Para realizar qualquer trabalho, o ideal é a contratação de especialistas capazes de acompanhar tecnicamente a execução dos serviços, assim você garante que tudo será feito com qualidade.
        </Text>
        <Title className="sub">Da anotação de resposabilidade técnica</Title>
        <Text>
        Art. 2º A ART é o instrumento que define, para os efeitos legais, os responsáveis técnicos pela execução de obras ou prestação de serviços relativos às profissões abrangidas pelo Sistema Confea/Crea.
        </Text>
        <Text>
        Art. 3º Todo contrato escrito ou verbal para execução de obras ou prestação de serviços relativos às profissões abrangidas pelo Sistema Confea/Crea fica sujeito ao registro da ART no Crea em cuja circunscrição for exercida a respectiva atividade.
        </Text>
        <Text>
        Parágrafo único. O disposto no caput deste artigo também se aplica ao vínculo de profissional, tanto a pessoa jurídica de direito público quanto de direito privado, para o desempenho de cargo ou função técnica que envolva atividades para as quais sejam necessários habilitação legal e conhecimentos técnicos nas profissões abrangidas pelo Sistema Confea/Crea
        </Text>
        <Title>Curiosidade</Title>
        <Text>
          <ul>
            <li>
              <Font to="http://normativos.confea.org.br/ementas/visualiza.asp?idEmenta=43481" 
                target="_blank" rel="noopener norefererr"> 
                Resolução nº 1.025, de 30 de outubro de 2009.
              </Font>
            </li>
            <li>
              <Font to="http://www.crea-sc.org.br/portal/index.php?cmd=artigos-detalhe&id=234#.XtHb6DdKjVN"
                target="_blank" rel="noopener norefererr"> 
              A responsabilidade técnica, civil e criminal dos profissionais do sistema Confea/Crea
              </Font>
            </li>
          </ul>
        </Text>
      </Content>
    </>
  )
}