export function validateMail(data) {
  console.log(data)

  if (
    data.nome === '' ||
    data.email === '' ||
    data.telefone === '' ||
    data.assunto === '' ||
    data.mensagem === ''
  ) {
    alert('Todos os campos são obrigatórios')
    return false
  }
  return true
}