import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import { validateMail } from '../services/Email'

import { MdEmail, MdLocationCity, MdPhone } from 'react-icons/md'
import { IoLogoWhatsapp } from 'react-icons/io'
import { AiFillInstagram } from 'react-icons/ai'
import { FaHandPointRight } from 'react-icons/fa'

import { FormContent, Title, Text, Image, Form, FormGroup, Label, Input, Message, Button } from './styles'

export default function TrabalheConosco() {

  const [ nome, setNome ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ telefone, setTelefone ] = useState('')
  const [ curriculo, setCurriculo ] = useState('')
  const [ mensagem, setMensagem ] = useState('')

  function handleSubmit(event) {
    
    const formData= new FormData()
    formData.append('curriculo', curriculo, curriculo.filename)
    
    const data= {
      nome, 
      email, 
      telefone, 
      assunto: 'currículo', 
      curriculo: formData, 
      mensagem
    }
    if ( !validateMail(data) ) {
      event.preventDefault()
    }
  }

  return (
    <FormContent>
      <Image />
      <Form id="formContact">
        <form 
          action="http://localhost/sendMail.php"
          method="POST"
          encType="multipart/form-data"
          onSubmit={event => {handleSubmit(event)}}
        > 
          <Title className="header">Trabalhe conosco</Title>
          <FormGroup>
            <Label>Nome</Label>
            <Input
              name="nome"
              onChange={event => setNome(event.target.value)}
              className="first"
            ></Input>
          </FormGroup>
          <FormGroup>
            <Label>Email</Label>
            <Input 
              name="email"
              onChange={event => setEmail(event.target.value)}
            ></Input>
          </FormGroup>
          <FormGroup>
            <Label>Telefone</Label>
            <Input 
              name="telefone"
              onChange={event => setTelefone(event.target.value)}
            ></Input>
          </FormGroup>
          <FormGroup>
            <Label>Currículo</Label>
            <Input type="file" 
              className="file"
              name="curriculo"
              onChange={event => setCurriculo(event.target.files[0])}              
            ></Input>
          </FormGroup>
          <FormGroup>
            <Label>Apresentação</Label>
            <Message rows="5"
              name="mensagem"
              onChange={event => setMensagem(event.target.value)}
            ></Message>
          </FormGroup>
          <FormGroup>
            <Button>Enviar</Button>
          </FormGroup>
        </form>

        <Title className="sub">Contato</Title>
        <Text>
          <MdEmail /> contato@iodeng.com<br />
          <a href="https://www.instagram.com/iod.eng" target="_blank"> 
            <AiFillInstagram /> IOD-ENG
          </a><br />
          <IoLogoWhatsapp /> (79) 99883-4131<br />
          <MdPhone /> (79) 3142-1036<br />
          <MdLocationCity /> Barra dos Coqueiros - SE - CEP:49.140-000<br />
          <Link to="contato">
            <FaHandPointRight /> Fale Conosco
          </Link>
        </Text>

      </Form>
    </FormContent>
  )
}