import React from 'react'

import container01 from '../assets/container/container-001.jpg'
import container02 from '../assets/container/container-002.jpg'
import container03 from '../assets/container/container-003.jpg'
import container04 from '../assets/container/container-004.jpg'
import container05 from '../assets/container/container-005.jpg'
import container06 from '../assets/container/container-006.jpg'
import { Content, Title, Text } from './styles'

export default function Projetos() {
  return (
    <Content>
      <Title className="header">Projetos</Title>

      <Title>Locação de Container Escritório</Title>
      <Text>
        Aluguel de Containers para escritório 
      </Text>
      <Text>
        <img src={container01} alt="Container para locação" />
        <img src={container02} alt="Container para locação" />
        <img src={container03} alt="Container para locação" />
        <img src={container04} alt="Container para locação" />
        <img src={container05} alt="Container para locação" />
        <img src={container06} alt="Container para locação" />
      </Text>
    </Content>
  )
}