import styled from 'styled-components'

export const TopHeader = styled.header`
  position: -webkit-sticky;
  position: sticky;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  background-color: #ebebfc;
  height: 150px;
  box-shadow: 0 3px 10px #666;

  h1 {
    width: 400px;
    padding: 0;
    border: 0;
    margin: 0;

    font-size: 42px;
    
    img {
      margin-left: 26px;
      vertical-align: middle;
      
      height: 72px;
    }
  }

  ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: right;
    padding: 0;
    padding-top: 20px;
    border: 0;
    margin: 0;
    margin-right: 18px;
    height: 100%;

    li {
      list-style: none;
      text-transform: uppercase;
      text-align: center;
      height: 50px;
      font-size: 18px;
      font-weight: bold;

      & + li {
        margin-left: 18px;
      }

      a {
        text-decoration: none;
        color: #000;
        transition: color 0.2s, border 0.2s, padding 0.5s;
  
        &:hover {
          color: #00537b;
          padding-top: 16px;
          border-top: 5px solid #00537b;
        }
      }
    }
  }

  transition: top 0.1s, height 0.3s, 
    width 0.3s, font-size 0.3s, 
    padding-top 0.3s;

  &.roledUp {
    top: 0;
    height: 90px;

    h1 {
      width: 300px;
      font-size: 32px;
    }

    img {
      height: 52px;
    }
    ul {
      padding-top: 5px;
      li {
        font-size: 20px;
        padding-top: 20px;
      }
    }
  }

  @media (max-width: 960px) {    
    position: -webkit-sticky;
    position: sticky;
    flex-shrink: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 6px 0;
    background-color: #ebebfc;
    height: 280px;

    h1 {
      width: 300px;
      padding: 0;
      border: 0;
      margin: 0;

      font-size: 30px;
      
      img {
        margin-left: 18px;
        vertical-align: middle;
        
        height: 48px;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      padding: 0;
      border: 0;
      margin: 0;
      margin-right: 12px;
      height: 100%;

      li {
        list-style: none;
        text-transform: uppercase;
        text-align: right;
        height: 36px;
        font-size: 14px;
        font-weight: bold;

        & + li {
          margin-top: 8px;
        }

        a {
          text-decoration: none;
          color: #000;
          transition: color 0.2s, border 0.2s, padding 0.5s;
    
          &:hover {
            color: #00537b;
            border-top: 0;
            padding-right: 10px;
            border-right: 5px solid #00537b;
          }
        }
      }
    }

    transition: top 0.1s, height 0.3s, width 0.3s, font-size 0.3s;

    &.roledUp {
      top: 0;
      height: 90px;

      h1 {
        width: 300px;
        font-size: 32px;
      }

      img {
        height: 52px;
      }
      ul {
        display: none;
        /* li {
          display: 
          font-size: 18px;
          padding-top: 8px;
        } */
      }
    }
  }

`

// comprovantedepagamento.br@telefonica.com -> vivo