import React from 'react'

import {Content, Title, Text, Font} from './styles'

export default function Servicos() {
  return (
    <Content>
      <Title className="header">Serviços</Title>
      <Title className="sub">Projeto</Title>
      <Text>
      Define-se projeto, na Construção Civil, como a “atividade ou serviço integrante do processo de construção, responsável pelo desenvolvimento, organização, registro e transmissão das características físicas e tecnológicas especificadas para uma obra, a serem consideradas na fase de execução” (MELHADO, 1994).        
      </Text>
      <Text>
      MELHADO, S.B. Qualidade do projeto na construção de edifícios: aplicação ao caso das empresas de incorporação e construção. São Paulo – Escola Politécnica, Universidade de São Paulo, 1994.
      </Text>
      <Title className="sub">Orçamento</Title>
      <Text>
      O orçamento de uma obra é o instrumento que determina os custos prováveis de uma obra antes de seu início (MATTOS, 2006).
      </Text>
      <Text>
      MATTOS, A. D. Como Preparar Orçamentos de Obras. 1. ed. SÃO PAULO: Editora PINI, 2006
      </Text>
      <Title className="sub">Construção Civil</Title>
      <Text>
      Segundo, MARCHIORI (1998), “os processos de construção devem ser executados de acordo com o previsto pelo planejamento, e controlados em conformidades com seus prazos e a qualidade estabelecida.”
      </Text>
      <Text>ALMEDIA, Fernanda Marchiori de, JÜNGLES, Antônio Edésio, PANZETER, Andréa Âgela; “Estudo da Evolução da Produtividade no Canteiro de Obras Sob a Ótica do Efeito Aprendizado”, Congresso Latino-Americano – Tecnologia e Gestão na Produção de Edifícios: Soluções para o terceiro Milênio, São Paulo, 1998 
      </Text>
      <Title className="sub">Topografia</Title>
      <Text>
      A topografia atua em áreas relativamente pequenas da superfície da Terra, de modo que sejam representadas particularidades da área, como construções, rios, vegetação, rodovias e ferrovias, relevos, limites entre terrenos e propriedades e outros detalhes de interesse em duas dimensões sobre os eixos Norte (Y) e Este (X), e representado por meio de cotas a altimetria (Z).
      </Text>
      <Text>
        <Font href="https://pt.wikipedia.org/wiki/Topografia"
          target="_blank" rel="noopener norefererr">
            Definição de Topografia em 21/05/2020
        </Font>
      </Text>
      <Title className="sub">Laudos Estruturais</Title>
      <Text>
      O laudo estrutural consiste na caracterização do padrão da edificação e seu estado de conservação, com a indicação das eventuais patologias encontradas em seus elementos estruturais, de vedação e revestimento, utilizando, quando necessário, elementos gráficos, obedecendo-se aos ditames da norma ABNT NBR 13752 (Perícias de Engenharia na Construção Civil).        
      </Text>
      <Title className="sub">Locação</Title>
      <Text>
      Uma locação, aluguel é um negócio jurídico onde uma das partes cede à outra o usufruto de um bem de sua propriedade em troca de um pagamento.
      </Text>
      <Text>
        <Font href="https://pt.wikipedia.org/wiki/Loca%C3%A7%C3%A3o"
          target="_blank" rel="noopener norefererr">
            Definição de Locação em 21/05/2020
          </Font>
      </Text>
    </Content>
  )
}