import React from 'react'
import Carousel from '../components/Carousel'

import imagem001 from '../assets/quem-somos.jpg'

import {Content, Title, Text} from './styles'

export default function QuemSomos() {
  return (
    <>
      <Carousel images={[imagem001]} />
      <Content>
        <Title className="header">QUEM SOMOS</Title>
        <Text>
          Desde 2018 a IOD ENGEHARIA LTDA, vem desempenhando um papel de sucesso no mercado sergipano atuando com qualidade, eficiência e segurança. Em nosso portifólio, atuamos com Projetos, Construção Civil, Laudos e Topografia.
        </Text>
        <Title>Visão</Title>
        <Text>Ampliar a participação no mercado na execução de serviços de engenharia.</Text>
        <Title>Missão</Title>
        <Text>Cumprir rigorosamente os contratos com a melhor técnica aliando a qualidade na execução dos serviços de Engenharia.</Text>
        <Title>Valores</Title>
        <Text>Ética, honestidade, Respeito nos relacionamentos com parceiros, clientes, fornecedores e colaboradores.</Text>
      </Content>
    </>
  )
}