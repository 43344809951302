import styled from 'styled-components'

import imagem from '../assets/contato.jpg'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: stretch;

  width: 100vw;

  padding: 0 15vw;
  padding-top: 20px; 
  margin-bottom: 70px;

  min-height: calc(100ch - 160px);
`

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 700;
  color: #00537b;
  margin-left: 38px;
  padding-top: 20px;
  padding-bottom: 12px;

  &.header {
    text-transform: uppercase;
  }

  &.sub {
    font-weight: 400;
  }
`

export const Text = styled.p`
  font-size: 24px;
  font-weight: 400;
  color: #666;

  & + p {
    margin-top: 18px;
  }

  ul {
    list-style: none;
  }

  img {
    max-height: 280px;
    padding: 0 8px;
  }

`

export const Picture = styled.div`
  border: 1px solid red;
  width: 100vmin;
  display: flex;
  justify-content: ${props => props.align};

  div {
    border: 1px solid blue;
    display: flex;
    justify-content: space-between;
    width: 60vmin;
  }

  img {
    max-height: 280px;
    vertical-align: top;
    display: inline-block;
  }

  & + div {
    margin-top: 8px;
  }
`

export const Font = styled.a`
  font-size: 18px;
  font-style: italic;
  color: #53537b;
  cursor: pointer;
`

export const FormContent = styled.div`
  display: flex;
  align-items: stretch;
`

export const Image = styled.div`
  flex: 1;
  background: url(${imagem}) no-repeat center;
  background-size: cover;
`

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  place-content: top;
  
  width: 100vmin;
  max-width: 70vw;
  padding-bottom: 70px;
  margin-bottom: 70px;
  padding: 0 5vmin;

  @media (max-width: 960px) {
    max-width: 100vw;
  }
`

export const FormGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vmin;
  padding: 0 5vmin;

  & + div {
    margin-top: 18px;
  }
`

export const Label = styled.label`
  display: block;
  width: 20vw;
  text-align: right;
  margin-right: 8px;

  font-size: 20px;
  color: #666;

  @media(max-width: 960px) {
    font-size: 16px;
  }
`

export const Input = styled.input`
  border: 0;
  border-left: 1px solid #ccd;
  border-bottom: 1px solid #ccd;
  background: #ebebfc;
  color: #000;
  font-family: 'Pragati Narrow';
  padding: 2px 15px;
  width: 70vmin;
  font-size: 20px;

  @media(max-width: 960px) {
    font-size: 16px;
  }
  
  transition: border-left 0.2s, border-color 0.2s;
  
  &:focus {
    outline: 0;
    border-left: 2px solid #00537b;
  }
  
  &.first {
    border-radius: 0 10px 0 0;
  }

  &.last {
    border-radius: 0 0 0 10px;
  }

  &.required {
    border-color: #900;
    background: #fcebeb;
  }

  &.file {
    font-size: 18px;

    @media(max-width: 960px) {
      font-size: 12px;
    }
  }
`

export const Message = styled.textarea`
  border: 0;
  border-left: 1px solid #ccd;
  border-bottom: 1px solid #ccd;
  border-radius: 0 0 0 10px;
  background: #ebebfc;
  color: #000;
  font-family: 'Pragati Narrow';
  font-size: 24px;
  padding: 2px 15px;
  width: 70vmin;

  transition: border-left 0.2s;

  &:focus {
    outline: 0;
    border-left: 2px solid #00537b;
  }
`

export const Button = styled.button`
  border: 0;
  border-radius: 0 10px 0 10px;
  padding: 4px 26px;
  background: #00537b;
  color: #fff;
  font-size: 22px;
  font-weight: bold;

  transition: background 0.2s;

  &:hover {
    background: #00436b;
  }
  &:focus {
    outline: 0;
  }
`