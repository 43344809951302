import styled from 'styled-components'

export const Panel = styled.div`
  margin-top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100vw;
  height: 50vh;

  padding: 0 15vw;

  background: url(${props => props.image}) no-repeat center;
  background-size: 1000px 500px;

  transition: background 1.5s !important;
`

export const NavButton = styled.button`
  width: 50px;
  background: transparent;
  border: 0;
  color: #ccc;
  display: flexbox;
  align-items: center;
  justify-content: center;

  transition: color 0.2s;

  &:hover {
    color: #00537b;
  }
  &:focus {
    outline: 0;
  }
`
