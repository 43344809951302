import React from 'react'

import Carousel from '../components/Carousel'
import imagem001 from '../assets/home-001.jpg'
import imagem002 from '../assets/home-002.jpg'

import {Content, Title, Text, Font} from './styles'

export default function Home() {
  return (
    <>
      <Carousel images={[imagem001, imagem002]}/>
      <Content>
        <Title>
          A Associação Americana de Engenheiros Civis define engenharia civil como sendo
        </Title>
        <Text>
          “... a profissão na qual o conhecimento físicos e matemáticos enriquecidos pelo estudo, experiência e prática são aplicados de forma correta para desenvolver meios de utilizar, economicamente, os materiais e as forças da natureza para o progressivo bem-estar da humanidade ao criar, melhorar e proteger o ambiente, ao criar instalações para a convivência da comunidade, indústrias e meios transporte e ao fornecer estruturas para o uso de toda a humanidade.”
        </Text>
        <Font 
          href="https://pt.wikipedia.org/wiki/Engenharia_civil#CITEREFAmerican_Society_of_Civil_Engineers2008"
          target="_blank" rel="noopener norefererr"
        >American Society of Civil Engineers 2008, p. 6
        </Font>
      </Content>
    </>
  )
}