import React from 'react'

import { MdEmail, MdLocationCity, MdPhone } from 'react-icons/md'
import { IoLogoWhatsapp } from 'react-icons/io'

import { Content } from './styles'
import logoMR from '../../assets/logo-marceloratton.svg'

export default function Footer() {
  return (
    <Content>
      <div className="contatos">
        <h1>Contatos</h1>
        <p>
          <MdEmail /> contato@iodeng.com<br />
          <IoLogoWhatsapp /> (79) 99883-4131<br />
        </p>
        <p>
          <MdPhone /> (79) 3142-1036<br />
          <MdLocationCity /> Barra dos Coqueiros - SE <br /> CEP:49.140-000<br />
        </p>
      </div>
      <div className="direitos">Todos os direitos reservados</div>
      <div className="merchan">Designed by 
        <a href="http://marceloratton.com/" 
          target="_blank"
          rel="noopener noreferrer"
        > 
          MarceloRatton.com
          <img src={logoMR} alt="MarceloRatton.com" />
        </a>
      </div>
    </Content>
  )
}