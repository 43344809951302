import styled from 'styled-components'

export const Content = styled.footer`
  flex-shrink: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 8px;
  padding-bottom: 20px;
  background-color: #000;
  color: #ccc;
  
  div.contatos {
    display: flex;
    flex-direction: row;
    
    h1 {
      font-size: 28px;
      font-weight: 400;
      color: #00537b;
      padding: 0;
      border: 0;
      margin: 0;
    }

    p {
      display: block;
      margin-left: 22px;
    }
  }

  div.direitos {
    font-size: 18px;
  }

  div.merchan {
    font-size: 14px;

    img {
      height: 18px;
      margin: 0 8px;
    }
  }

`