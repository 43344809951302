import React, { useState } from 'react'
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa'

import { Panel, NavButton } from './styles'

export default function Carousel({images}) {
  const [pos, setPos] = useState(0)
  const [image, setImage]= useState(images[pos])

  function handleNav(direction) {
    let nPos = pos + direction

    if (nPos === images.length) nPos= 0
    if (nPos === -1) nPos= images.length-1 

    setImage(images[nPos])
    setPos(nPos)
  }

  return (
    <>
      <Panel image={image}>
        <NavButton onClick={() => handleNav(-1)}><FaChevronLeft size={32} /></NavButton>
        <NavButton onClick={() => handleNav(1)}><FaChevronRight size={32} /></NavButton>
      </Panel>
    </>

  )
}
